<template>
  <div>
    <!--begin::User-->
    <div>
      <h3 class="card-label">
        {{ $t('notifications_config.notifications_config') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>

      <div class="row">
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <label>{{ $t('notifications_config.document_type') }}<span class="text-danger">*</span></label>
                <select name="document_type" id="document_type" v-model="data.doc_type" class="custom-select" :class="validation && validation.doc_type ? 'is-invalid' : ''">
                  <option v-for="row in document_type_list" :value="row.id" :key="row.id">{{ row.title }}</option>
                </select>
                <span v-if="validation && validation.doc_type" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.doc_type[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('notifications_config.document_slug') }}<span class="text-danger">*</span></label>
                <input :value="data.slug" disabled class="form-control" :class="validation && validation.slug ? 'is-invalid' : ''">
                <span v-if="validation && validation.slug" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.slug[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('notifications_config.action') }}<span class="text-danger">*</span></label>
                <select name="action" id="action" v-model="data.action" class="custom-select" :class="validation && validation.action ? 'is-invalid' : ''">
                  <option v-for="row in action_list" :value="row.id" :key="row.id">{{ row.title }}</option>
                </select>
                <span v-if="validation && validation.action" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.action[0] }}
                            </span>
              </div>
              <div class="col-lg-12 mb-5">
                <label>{{ $t('notifications_config.status') }}</label>
                <select name="client_layout" id="client_layout" v-model="data.status" class="custom-select" :class="validation && validation.status ? 'is-invalid' : ''">
                  <option v-for="row in status_list" :value="row.id" :key="row.id">{{ row.title }}</option>
                </select>
                <span class="form-text text-muted" style="text-align:end">{{ $t('select_doc_type') + ' ' + $t('notifications_config.status') }}.</span>
                <span v-if="validation && validation.status" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.status[0] }}
                            </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <label>{{ $t('notifications_config.interested_roles') }}</label>
                <multiselect v-model="inner_interested_roles"
                             label="name"
                             track-by="id"
                             :placeholder="$t('notifications_config.interested_roles')"
                             :options="interested_roles_list"
                             :multiple="true"
                             :taggable="false"
                             :class="validation && validation.interested_roles ? 'is-invalid' : ''"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false">
                </multiselect>
                <span v-if="validation && validation.interested_roles" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.interested_roles[0] }}
                            </span>
              </div>
              <div class="col-lg-12 mb-5">
                <label>{{ $t('notifications_config.interested_users') }}</label>
                <div class="input-group mb-3">
                  <multiselect v-model="inner_interested_users"
                               label="name"
                               track-by="id"
                               :placeholder="$t('notifications_config.interested_users')"
                               :options="interested_users_list"
                               :multiple="true"
                               :taggable="false"
                               :class="validation && validation.interested_users ? 'is-invalid' : ''"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               @search-change="getUsersList($event)">
                  </multiselect>
                  <div class="input-group-prepend">
                    <a class="btn btn-primary" href="/users/roles" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                  </div>
                </div>
                <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters') + ' ' + $t('employees.manager') }}.</span>
                <span v-if="validation && validation.interested_users" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.interested_users[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <b-form-checkbox size="lg" v-model="data.is_active" name="check-button" switch>{{ $t('notifications_config.is_active') }}</b-form-checkbox>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 mt-5">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('notifications_config.columns') }}</label>
                <multiselect v-model="data.columns_name"
                             :placeholder="$t('document_type_setting.columns')"
                             :options="columns_doc_list"
                             :multiple="true"
                             :taggable="true"
                             :class="validation && validation.columns_name ? 'is-invalid' : ''"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false">
                </multiselect>
                <span v-if="validation && validation.columns_name" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.columns_doc[0] }}
                            </span>
              </div>
              <div class="col-lg-12 mb-5">
                <label>{{ $t('notifications_config.content') }}</label>

                <div class="d-flex w-100 flex-wrap mb-4 mt-4">
                  <b-badge class="m-2 cursor-pointer" @click="setInContent('{{added_by}}')">{{ $t('notifications_config.added_by') }}</b-badge>
                  <b-badge class="m-2 cursor-pointer" @click="setInContent('{{customer}}')">{{ $t('notifications_config.customer') }}</b-badge>
                  <b-badge class="m-2 cursor-pointer" @click="setInContent('{{supplier}}')">{{ $t('notifications_config.supplier') }}</b-badge>
                  <b-badge class="m-2 cursor-pointer" @click="setInContent('{{doc_type}}')">{{ $t('notifications_config.document_type') }}</b-badge>
                  <b-badge class="m-2 cursor-pointer" @click="setInContent('{{action}}')">{{ $t('notifications_config.action') }}</b-badge>
                  <b-badge class="m-2 cursor-pointer" @click="setInContent('{{transaction_id}}')">{{ $t('notifications_config.transaction_id') }}</b-badge>
                  <b-badge class="m-2 cursor-pointer" @click="setInContent('{{status}}')">{{ $t('notifications_config.status') }}</b-badge>

                  <b-badge class="m-2 cursor-pointer" v-for="(row, index) in data.columns_name" :key="index" @click="setInContent('{{'+row+'}}')">{{ row }}</b-badge>
                </div>
                <textarea name="contents" id="contents" v-model="data.contents" class="form-control" :class="validation && validation.contents ? 'is-invalid' : ''"></textarea>
                <span v-if="validation && validation.contents" class="fv-plugins-message-container invalid-feedback">
                            {{ validation.contents[0] }}
                        </span>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="button" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
          </div>
        </div>
      </div>


    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "from-notifications-config",
  data() {
    return {
      mainRoute: 'settings/notifications_config',
      mainRouteDependency: 'base/dependency',
      mainRouteByDocType: 'base/dependency/data_doc',

      document_type_list: [],
      status_list: [],
      columns_doc_list: [],
      action_list: [],
      interested_roles_list: [],
      interested_users_list: [],

      idEdit: this.$route.params.id ? this.$route.params.id : null,

      data: {
        doc_type: null,
        slug: null,
        status: null,
        columns_name: [],
        action: null,
        interested_roles: [],
        interested_users: [],
        contents: null,
        is_active: true,
      },
      isEditing: false,
      validation: null,
      inner_interested_roles: [],
      inner_interested_users: [],

    };
  },

  watch: {
    'data.doc_type': function (val) {
      if (val) {
        let _doc = this.document_type_list.find((row) => row.id == val);
        if (_doc) {
          this.data.slug = _doc.slug;
        }
        // let promise = this.getDataByDocType();
        // Promise.all([promise]).then(() => {
        this.getStatusList(val);
        this.getColumnsDocList(val);

        // });
      } else {
        this.status_list = [];
        this.columns_doc_list = [];
      }
    },
    inner_interested_roles: function (val) {
      if (val) {
        this.data.interested_roles = [];
        this.data.interested_roles = val.map(row => row.id);
      }
    },
    inner_interested_users: function (val) {
      if (val) {
        this.data.interested_users = [];
        this.data.interested_users = val.map(row => row.id);
      }
    },
  },
  methods: {
    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {
      ApiService.post(this.mainRoute, {
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/settings/notifications-config');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    update() {
      ApiService.put(this.mainRoute + '/' + this.idEdit, {
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/settings/notifications-config');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    getData() {
      ApiService.get(this.mainRoute + '/' + this.idEdit).then((response) => {
        this.isEditing = true;
        this.data.doc_type = response.data.data.doc_type;
        this.data.slug = response.data.data.slug;
        this.data.status = response.data.data.status;
        this.data.columns_name = response.data.data.columns_name;
        this.data.action = response.data.data.action;
        this.data.interested_roles = response.data.data.interested_roles;
        this.data.interested_users = response.data.data.interested_users;
        this.data.contents = response.data.data.contents;
        this.data.is_active = response.data.data.is_active;
        this.inner_interested_roles = response.data.data.inner_interested_roles;
        this.inner_interested_users = response.data.data.inner_interested_users;
        // this.inner_interested_roles = this.interested_roles_list.filter( row => response.data.data.interested_roles.includes(row.id));
        // this.inner_interested_users = this.interested_users_list.filter( row => response.data.data.interested_users.includes(row.id));

      });
    },
    // async getDataByDocType() {
    //     this.validation = null;
    //     await ApiService.get(this.mainRouteByDocType + '/' + this.data.doc_type).then((response) => {
    //         if (response.data.data){
    //             this.isEditing = true;
    //             this.idEdit = response.data.data.id;
    //             this.data.doc_type = response.data.data.doc_type;
    //             this.data.name = response.data.data.name;
    //             this.data.slug = response.data.data.slug;
    //             this.data.status = response.data.data.status;
    //             this.data.columns_doc = response.data.data.columns_doc;
    //         }else {
    //             this.isEditing = false;
    //             this.idEdit = null;
    //             this.data.name = null;
    //             //this.data.slug = null;
    //             this.data.status = null;
    //             this.data.columns_doc = null;
    //         }
    //     });
    // },

    getDocumentTypeList() {
      ApiService.get(this.mainRouteDependency + "/doc_type_list").then((response) => {
        this.document_type_list = response.data.data;
      });
    },

    getStatusList(id) {
      ApiService.get(this.mainRouteDependency + "/status_by_doc_type/" + id).then((response) => {
        this.status_list = response.data.data;
      });
    },

    getColumnsDocList(id) {
      ApiService.get(this.mainRouteDependency + "/columns_doc/" + id).then((response) => {
        this.columns_doc_list = response.data.data;
      });
    },
    getActionsList() {
      ApiService.get(this.mainRouteDependency + "/actions_list").then((response) => {
        this.action_list = response.data.data;
      });
    },
    async getRolesList() {
      await ApiService.get(this.mainRouteDependency + "/roles").then((response) => {
        this.interested_roles_list = response.data.data.map((role) => {
          return {
            id: role.id,
            name: role.name,
          };
        });
      });
    },
    async getUsersList(filter) {
      if (filter && filter.length >= 3)
        await ApiService.get(this.mainRouteDependency + "/users", {params: {filter: filter}}).then((response) => {
          this.interested_users_list = response.data.data;
        });
    },
    setInContent(tag) {
      let txtarea = document.getElementById("contents");
      let strPos = 0;
      let br = ((txtarea.selectionStart || txtarea.selectionStart == '0') ?
          "ff" : (document.selection ? "ie" : false));
      if (br == "ie") {
        txtarea.focus();
        var range = document.selection.createRange();
        range.moveStart('character', -txtarea.value.length);
        strPos = range.text.length;
      } else if (br == "ff") strPos = txtarea.selectionStart;
      let front = (txtarea.value).substring(0, strPos);
      let back = (txtarea.value).substring(strPos, txtarea.value.length);
      txtarea.value = front + tag + back;
      strPos = strPos + tag.length;
      if (br == "ie") {
        txtarea.focus();
        let range = document.selection.createRange();
        range.moveStart('character', -txtarea.value.length);
        range.moveStart('character', strPos);
        range.moveEnd('character', 0);
        range.select();
      } else if (br == "ff") {
        txtarea.selectionStart = strPos;
        txtarea.selectionEnd = strPos;
        txtarea.focus();
      }

    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.notifications_config"), route: '/settings/notifications-config'}, {title: (this.idEdit ? this.$t('update') : this.$t('create'))}]);

    this.getDocumentTypeList();
    this.getActionsList();
    this.getRolesList();

    if (this.idEdit) {
      this.getData();

    }

  },
};
</script>


